@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.body {
    max-width: 960px;
    margin-inline: auto;
}

.title {
    font-size: 2rem;
    font-style: italic;
    font-weight: 700;
}

.btn {
    background-color: #fca37b;
    padding: 7px 16px;
    border-radius: 5px;
    color: #fff;
}
.icon {
    margin-left: 16px;
    color: #ff905d;
    padding: 12px;
    font-size: 2rem;
}

.nav-link {
    font-size: 1rem;
    font-weight: 600;
    color: black;
    padding: 10px;
}

.active {
    background-color: #ffc4a9;
    border-radius: 5px;
}

.category li {
    background-color: #ff905d;
    padding: 10px;
    border-radius: 10px;

}

.menu-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;

}

.shadowed {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.carded {
    display: grid;
    grid-template-columns: auto, auto, auto;
}


.pop-up-modal {
    position: absolute;
    top: 30%;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: all 5s ease-in;
}

.pop-up-modal video {
    width: 650px;
    padding-top: 10px;
}

form input, textarea {
    padding: 10px;
    width: 550px;
    margin-bottom: 10px;
    border-radius: 5px;
    outline: none;
    resize: none;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.card-shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}




@media not all and (min-width: 640px) {
    body {
        margin-inline: 20px;
    }
    .nav-mobile {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        background-color: burlywood;
        padding: 15px;
    }
    .hero-card > div:nth-child(n+3) {
        display: none;
    }

    .pop-up-modal video {
        width: 420px;
        padding-top: 10px;
    }

    form input, textarea {
        width: 380px;
    }
}





